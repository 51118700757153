<template>
  <div>
    <!-- <navigation/> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerDrop">
        <template v-slot:divider>      
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs> 
      <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner"  :ownerDesc="ownerDesc" v-if="ownerDrop"/>
      </div>
      <v-content class="white maincontent">
        <div class="pb-4" v-show="shownotifications">
          <div class="d-flex justify-space-between align-center">
            <div>
              <h2 class="text-center font-weigt-bold">Notifications</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
            </div>
          <div class=" d-flex flex-md-row flex-sm-column justify-end align-md-end justify-md-space-between">
              <div> 
                <v-chip outlined
                  @click="makeSelectedEmpty"
                  class="ma-2 white"
                  v-if="selectedFun.length"
                >
                  <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span>  {{ selectedFun.length }} selected
                </v-chip>                   
              </div>
              <div>
                <v-btn text class="tertiary2--text nocap py-5" @click.stop="addnotifications = true; isValidForm=true;">
                  <v-icon class="pr-1 add" style="font-size:26px">mdi-checkbox-multiple-marked-circle-outline </v-icon>
                    Add Notifications
                </v-btn>
                <v-btn text
                  @click.stop="deletenotifications = true"
                  :disabled="!selectedNotification.length"
                  class="error--text nocap py-5"
                >
                  <v-icon class="pr-1 add">mdi-delete-circle </v-icon>
                  Delete
                </v-btn>
                <v-btn text         
                  @click="showOutage()"  
                  class="tertiary2--text nocap py-5"
                >
                  <v-icon class="pr-1 add" style="font-size:26px">mdi-format-list-bulleted</v-icon>
                  Show Outage
                </v-btn>
                </div>
                </div>
            </div>
        </div>
        <div class="pb-4" v-show="showoutage">
          <div class="d-flex justify-space-between align-center">
            <div>
              <h2 class="text-center font-weigt-bold">Outage</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
            </div>
            <div class=" d-flex flex-md-row flex-sm-column justify-end align-md-end justify-md-space-between">
              <div> 
                <v-chip outlined
                  @click="makeSelectedEmpty"
                  class="ma-2 white"
                v-if="selectedFun.length"
                >
                  <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span>  {{ selectedFun.length }} selected
                </v-chip>                   
              </div>
                  <v-btn text class="tertiary2--text nocap py-5" @click.stop="addoutage = true; isValidForm=true;">
                  <v-icon class="pr-1 add" style="font-size:26px">mdi-checkbox-multiple-marked-circle-outline </v-icon>
                    Add Outage
                  </v-btn>
                <v-btn text
                  @click.stop="deleteoutage = true"
                  :disabled="!selectedOutage.length"
                  class="error--text nocap py-5"
                >
                  <v-icon class="pr-1 add">mdi-delete-circle </v-icon>
                  Delete
                </v-btn>
                <v-btn text         
                  @click="showNotification()"  
                  class="tertiary2--text nocap py-5"
                >
                  <v-icon class="pr-1 add" style="font-size:26px">mdi-format-list-bulleted</v-icon>
                  Show Notifications
                </v-btn>
            </div>
          </div>
          
        </div>
        <div>
          <v-data-table
            :mobile-breakpoint="0"
            v-model="selectedNotification"
            v-show="shownotifications"
            :headers="notificationsheaders"
            :items="notificationvalue"
            item-key="id"
            item.value=""
            show-select
            @click:row="viewnotificationdetails"
            class="elevation-1 listingTable"
          >
          </v-data-table>
        </div>
        <div>
          <v-data-table
            :mobile-breakpoint="0"
            v-model="selectedOutage"
            v-show="showoutage"
            :headers="outageheaders"
            :items="outagevalue"
            item-key="id"
            item.value=""
            show-select
            @click:row="viewOutagedetails"
            class="elevation-1 listingTable"
          >
          </v-data-table>
        </div>
        <!----------------------Outage-------------------------->
        
          
          
          <!-------------end----------------->

        <!--------------------------Add notifications start------------------------------------------>

        <v-row justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="addnotifications"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">Add Notifications</h3>
                       <div class="closeicon-right">
                      <v-icon   @click="clearnotifyObj()" class="text-right"
                        >mdi-close-circle-outline
                      </v-icon>
                      </div>
                    </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px">
                    <addNotificationInfo 
                    
                    :notificationitem="notificationitem"
                    :isValidForm="isValidForm"
                    />
                    <div class="px-md-10">
                      <v-alert
                        text
                        :type="response_status"
                        v-if="response_msg.length"
                      >
                        {{ response_msg }}
                      </v-alert>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="px-10 py-6 text-center">
                   
                    <v-btn
                      class="nocap"
                      large
                      text
                      @click="clearnotifyObj()"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      class="nocap mx-5"
                      large
                      color="primary"
                      @click="Addnotifications()"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>
        <!-------------------------------------end--------------------------------------------------->
        <!-- --------------------------Success pop up ---------------------------------------->
        <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="successMessage"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="192"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Success</div>
                  </v-card-title>
                  <p v-if="response_message.length">{{response_message}}</p>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="successMessage = false">Done </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!--------------------------------------------------- End ----------------------------->
        <!-------------------------------------start delete popup--------------------------------->
        <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="deletenotifications"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-avatar
                    color="error"
                    size="62"
                  >
                    <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Delete Confirmation</div>
                  </v-card-title>
                  Are you sure want to delete the selected function?
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap mx-5" text @click="deletenotifications = false">Cancel </v-btn>
                      <v-btn class="nocap mx-5" color="error" @click="deletenotify()">Delete </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>

          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="deleteoutage"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-avatar
                    color="error"
                    size="62"
                  >
                    <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Delete Confirmation</div>
                  </v-card-title>
                  Are you sure want to delete the selected function?
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap mx-5" text @click="deleteoutage = false">Cancel </v-btn>
                      <v-btn class="nocap mx-5" color="error" @click="deleteoutage()">Delete </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          
        <!---------------------------------end --------------------------------------------------->
        <v-row justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="addoutage"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">Add Outage</h3>
                       <div class="closeicon-right">
                      <v-icon   @click="clearoutageObj()" class="text-right"
                        >mdi-close-circle-outline
                      </v-icon>
                      </div>
                    </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px">
                    <addoutage :outageitem="outageitem" :isValidForm="isValidForm"/>
                    <div class="px-md-10">
                      <v-alert
                        text
                        :type="response_status"
                        v-if="response_msg.length"
                      >
                        {{ response_msg }}
                      </v-alert>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="px-10 py-6 text-center">
                   
                    <v-btn
                      class="nocap"
                      large
                      text
                      @click="clearoutageObj()"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      class="nocap mx-5"
                      large
                      color="primary"
                      @click="Addoutage()"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>

        <!-- -------++++ Function Detail Pages +++++--------->
        <v-dialog v-model="detailnotificationdialog" width="1000" scrollable>
            <detailnotification
              @closeDetail="closenotifyDialog"
              :rowItem="selectednotificationRowData"
              @notificationseditopen="notificationseditopen"
            />
          </v-dialog>
          <!-- ----------------------------- -->
          <!-- -------++++ Edit function +++++--------->
          <v-dialog
            v-model="editnotificationsdialog"
            width="1000"
            scrollable
            class="d-none"
            persistent
          >
            <editnotification :notificationitem="selectednotificationRowData" @closenotifyEdit="closenotifyEdit" @saveFunEditedData="saveNotificationEdit" :response_status="response_status" :response_msg="response_msg" />
          </v-dialog>
          <!-- ----------------------------- -->
          <!-- -------++++ Outage Detail Pages +++++--------->
        <v-dialog v-model="detailoutagedialog" width="1000" scrollable>
            <detailoutage
                :isValidForm = "isValidForm"
                @closeDetail="closeoutageDialog"
                :rowoutageItem="selectedoutageRowData"
                @outageeditopen="outageeditopen"
            />
        </v-dialog>
          <!-- ----------------------------- -->
          <!-- -------++++ Edit Outage +++++--------->
        <v-dialog
            v-model="editoutagedialog"
            width="1000"
            scrollable
            class="d-none"
            persistent
        >
            <editoutage 
                :isValidForm = "isValidForm"
                :outageitem="selectedoutageRowData" 
                @closeoutageEdit="closeoutageEdit" 
                @saveFunEditedData="saveoutageEdit" 
                :response_status="response_status" 
                :response_msg="response_msg" />
        </v-dialog>
          <!-- ----------------------------- -->
      </v-content>
    </div>
    <loaderimg v-if="loader"/>
  </div>
</template> 
<script>
import API_Util from "../services/util/API_Util.js";
import addNotificationInfo from "./notifyInfo/addNotificationInfo.vue";
import editnotification from "./editnotification.vue";
import detailnotification from "./detailnotification.vue";
import addoutage from "./addoutage.vue";
import editoutage from "./editoutage.vue";
import detailoutage from "./detailoutage.vue";
export default {
  components:{
    addNotificationInfo,
    editnotification,
    detailnotification,
    addoutage,
    editoutage,
    detailoutage
  },
  name:"notifications",
  data: () => ({
    notificationvalue:[],
    outagevalue:[],
    apiKey: "",
    outageheaders : [],
    notificationheaders : [],
    ownerDrop : "",
    selectedFun: [],
    selectedOwner : "",
    ownerDesc : "",
    selectedNotification:{},
    selectedOutage: {},
    showoutage:false,
    shownotifications:true,
    addnotifications : false,
    isValidForm : false,
    detailnotificationdialog : false,
    editnotificationsdialog : false,
    deletenotifications : false,
    selectednotificationRowData: {},
    selectedoutageRowData: {},
    response_msg : "",
    response_message:"",
    response_status : "",
    successMessage:false,
    addoutage : false,
    detailoutagedialog : false,
    editoutagedialog : false,
    deleteoutage : false,
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Platform Setting - Notifications",
        disabled: true,
        href: "",
      },
    ],
    notificationitem : {
      id: 0,
      type : "notify",
      message : "",
      version : ""
    }

  }),
  created(){
    this.loader = true;
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getnotification();
    this.getoutagelist();
    this.getoutageheaders();
    this.getnotificationsheaders();
    this.clearNotificationData();
    this.clearOutageData();
  },
  methods:{
    clearnotifyObj(){
      this.addnotifications = false;
      this.refreshnotifyData();
    },
    clearoutageObj(){
      this.addoutage = false;
      this.refreshoutageData();
    },
    clearNotificationData(){
      this.notificationitem = {
        id: 0,
        type : "notify",
        message : "",
        version : ""
      }
    },
    clearOutageData(){
      this.outageitem = {
        id: 0,
        status: "Active",
        start_date_time : "",
        end_date_time : "",
        outage:""
      }
    },
    refreshnotifyData() {
      this.notificationitem.msgtype = "notify";
      this.notificationitem.message = "";
      this.notificationitem.version = "";
    },
    refreshoutageData() {
      this.outageitem.status = "Active";
      this.outageitem.start_date_time = "";
      this.outageitem.end_date_time = "";
      this.outageitem.outage = "";
    },
    showOutage(){
      this.shownotifications=false;
      this.showoutage=true;
    },
    showNotification(){
      this.showoutage=false;
      this.shownotifications=true;
    },
    closeoutageDialog() {
      this.detailoutagedialog = false;
      this.editoutagedialog = true;
    },
    closenotifyDialog() {
      this.detailnotificationdialog = false;
      this.editnotificationsdialog = true;
    },
    closenotifyEdit(status) {
      this.editnotificationsdialog = status;
    },
    closeoutageEdit(status) {
      this.editoutagedialog = status;
    },
    saveNotificationEdit(emittedData) {
      this.notificationitem = emittedData;
      console.log(this.notificationitem)
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.updatenotifications , this.notificationitem , { headers })
        .then((response) => {
          var resp = response.data;
          if (resp.message == "Successfully Updated" ){
            this.response_message = "Successfully Updated";
            this.successMessage = true;
          } else {
            this.response_msg = "Something Error";
            this.response_status = "Error";
              setTimeout(() => {
                this.response_msg = "";
              }, 2000);
          }
          setTimeout(() => {
            this.clearNotificationData();
            this.editnotificationsdialog = false;
            this.getnotification();
          }, 3000)
        });
      
    },
    saveoutageEdit(emittedData) {
      this.outageitem = emittedData;
      console.log(this.outageitem)
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.llmupdateoutage , this.outageitem , { headers })
        .then((response) => {
          var resp = response.data;
          if (resp.status == "Success" ){
            this.response_message = "Successfully Updated";
            this.successMessage = true;
          } else {
            this.response_msg = "Something Error";
            this.response_status = "Error";
              setTimeout(() => {
                this.response_msg = "";
              }, 2000);
          }
          setTimeout(() => {
            this.clearOutageData();
            this.editoutagedialog = false;
            this.getoutagelist();
          }, 3000)
        });
      
    },
    getnotification() {
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.getnotifications , {}, { headers })
        .then((response) => {
          console.log(response)
          this.notificationvalue = response.data.data.all;
          console.log(this.notificationvalue)
          this.loader = false;
        });
    },
    getoutagelist() {
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.llmoutagelist , {}, { headers })
        .then((response) => {
          console.log(response)
          this.outagevalue = response.data.data;
          console.log(this.outagevalue)
          this.loader = false;
        });
    },
    changeOwner(obj) {
      this.loader = true;
      localStorage.setItem("owner", obj.ownerId);
      localStorage.setItem('ownerName', obj.ownerName);
      localStorage.setItem('ownerType', obj.ownerType);
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit('ownerchange',obj.ownerType);
      this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.getnotification();
    },
    getnotificationsheaders(){

      this.notificationsheaders = [

        { text:"TYPE", align: "start", sortable: true, value: "type",width: "10%"},
        { text: "MESSAGE", value: "message",width: "50%" },
        { text: "VERSION", value: "version",width: "10%" },
        { text: "CREATED_BY", value: "created_by" ,width: "10%"},
        { text: "DATE_CREATED", value: "date_created",width: "20%" },
      ]
    },
    getoutageheaders(){

      this.outageheaders = [

        { text:"OUTAGE", align: "start", sortable: true, value: "outage",width: "50%"},
        { text: "STATUS", value: "status" ,width: "10%"},
        { text: "START DATE", value: "start_date_time",width: "20%" },
        { text: "END DATE", value: "end_date_time" ,width: "20%"},
      ]
    },
    notificationseditopen(value) {
      this.detailnotificationdialog = value;
    },
    outageeditopen(value) {
      this.detailoutagedialog = value;
    },
    Addnotifications(){
      if(this.notificationitem.type == ""){
          this.isValidForm = false;
      } else if (this.notificationitem.message == ""){
          this.isValidForm = false;
      } else if (this.notificationitem.version == ""){
          this.isValidForm = false;
      } else {
          this.isValidForm = true;
        var data = this.notificationitem;
        const headers = {
          "Content-Type": "application/json",
          "x-api-key": this.apiKey,
        };
        API_Util.URLS.axios
          .post(API_Util.URLS.insertnotifications , data, { headers })
          .then((response) => {
            var resp = response.data;
            if (resp.message == "Successfully Inserted" ){
              this.response_message = "Successfully Added";
              this.successMessage = true;
            } else {
              this.response_msg = "Something Error";
              this.response_status = "Error";
                setTimeout(() => {
                  this.response_msg = "";
                }, 2000);
            }
            setTimeout(() => {
              this.clearNotificationData();
              this.addnotifications = false;
              this.getnotification();
            }, 3000)
          })
          .catch((err) => console.log(err));
      }
    },
    Addoutage(){
        if(this.outageitem.status == ""){
          this.isValidForm = false;
        } else if (this.outageitem.outage == ""){
            this.isValidForm = false;
        } else {
            this.isValidForm = true;
            var data = this.outageitem;
            const headers = {
                "Content-Type": "application/json",
                "x-api-key": this.apiKey,
            };
            API_Util.URLS.axios
                .post(API_Util.URLS.llmaddoutage , data, { headers })
                .then((response) => {
                var resp = response.data;
                console.log(resp)
                if (resp.status == "Success" ){
                    this.response_message = "Successfully Added";
                    this.successMessage = true;
                } else {
                    this.response_message = resp.msg;
                    this.response_status = "Error";
                    setTimeout(() => {
                        this.response_msg = "";
                    }, 2000);
                }
                setTimeout(() => {
                    this.clearOutageData();
                    this.addoutage = false;
                    this.getoutagelist();
                }, 3000)
                })
                .catch((err) => console.log(err));
        }
    },
    deletenotify(){
      console.log(this.selectedNotification)
      const headers = { "x-api-key": this.apiKey };
      this.selectedNotification.forEach(function (arrayItem) {
        const data = {
          "id": arrayItem.id,
          "owner_id":localStorage.getItem("owner")
        }
        API_Util.URLS.axios
          .post(API_Util.URLS.deletenotifications , data, {
            headers,
          })
          .then((response) => response)
          .catch((err) => console.log(err));
      });
      this.selectedNotification = [];
      this.deletenotifications = false;
      this.getnotification();

    },
    deleteoutage(){
      console.log(this.selectedOutage)
      const headers = { "x-api-key": this.apiKey };
      this.selectedOutage.forEach(function (arrayItem) {
        const data = {
          "id": arrayItem.id,
          "owner_id":localStorage.getItem("owner")
        }
        API_Util.URLS.axios
          .post(API_Util.URLS.llmdeleteoutage , data, {
            headers,
          })
          .then((response) => response)
          .catch((err) => console.log(err));
      });
      this.selectedOutage = [];
      this.deleteoutage = false;
      this.getoutagelist();

    },
    viewnotificationdetails(obj) {
      this.detailnotificationdialog = true;
      this.selectednotificationRowData = obj
    },
    viewOutagedetails(obj) {                
      this.detailoutagedialog = true;
      this.selectedoutageRowData = obj
    },

  }
};
</script>
<style lang="scss">
</style>

